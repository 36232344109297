
import { defineComponent } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { Strings } from "@/resources";
import { Routes } from "@/router";
import { useStore } from "@/store";
import { SubscribeStatus, Toast } from "@/types";

export default defineComponent({
  name: "Subscribe",
  setup() {
    const router = useRouter();
    const store = useStore();

    const params = new Map([
      ["status", router.currentRoute.value.query.status?.toString()],
      ["customerId", router.currentRoute.value.query.customerId?.toString()],
      ["productCode", router.currentRoute.value.query.productCode?.toString()],
      ["subscriberCode", router.currentRoute.value.query.subscriberCode?.toString()],
    ]);
    params.forEach((v) => {
      if (v === undefined || v === null || Array.isArray(v)) {
        Toast.error(store, Strings.error());
        router.push(Routes.HOME);
        return;
      }
    });

    switch (params.get("status")) {
      case SubscribeStatus.SUCCESS:
        if (!store.state.session.isValid()) {
          return;
        }

        store.dispatch("subscribeConfirm", {
          customerId: params.get("customerId") ?? "",
          productCode: params.get("productCode") ?? "",
          subscriberCode: params.get("subscriberCode") ?? "",
        });
        return;
      case SubscribeStatus.USER_ALREADY_EXISTS:
        if (!store.state.session.isValid()) {
          Toast.info(store, "Looks like you're already subscribed, please sign in.");
          store.dispatch("setModal", "VSignIn");
        } else {
          Toast.info(store, "Looks like you're already subscribed.");
        }
        return;
      case undefined:
        Toast.info(store, "Query parameter undefined, please subscribe from AWS Marketplace subscription page.");
        router.push(Routes.HOME);
        return;
      case SubscribeStatus.BAD_REQUEST:
      case SubscribeStatus.TOKEN_INVALID:
      case SubscribeStatus.TOKEN_MISSING:
      case SubscribeStatus.INTERNAL_SERVER_ERROR:
      default:
        console.error(`Subscribe attempt failed: ${params.get("status")}`);
        Toast.error(store, Strings.error());
        router.push(Routes.HOME);
        return;
    }
  },
  methods: {
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    setModal(componentName: string): void {
      if (this.loading("subscribeConfirm")) {
        return;
      }

      this.$store.dispatch("setModal", componentName);
    },
  },
});
